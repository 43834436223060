import { usePostHog } from "posthog-js/react";
import env_utils from "utils/env_utils";


type UserAnalytics = {
  userId: string;
  userRole: string;
  companyId?: string;
  companyName?: string;
};

const useAppAnalytics = () => {
  const posthog = usePostHog();

  const identifyUser = ({ userId, userRole, companyId, companyName }: UserAnalytics) => {

    if (!env_utils.isProd()) {
      console.debug("Debug: Identify user");
    }
    posthog.identify(String(userId), { user_id: userId, user_role: userRole, company_id: companyId });

    // Identify company if it exists
    if (companyId) posthog.group('company', companyId, { name: companyName })
  };

  const resetUser = () => {
    if (!env_utils.isProd()) {
      console.debug("Debug: Reset user");
    }
    posthog.reset();
  };

  const capturePageView = () => {
    if (!env_utils.isProd()) {
      console.debug("Debug: Register pageview");
    }

    posthog.capture("$pageview");
  };

  return {
    identifyUser,
    resetUser,
    capturePageView,
  };
};

export default useAppAnalytics;
