function getCurrentEnv(): string {
  const currentUrl = window.location.host;
  const currentApiUrl = process.env.REACT_APP_API_URL || "";

  if (currentUrl.includes("localhost")) {
    return currentUrl.split(":")[0];
  }

  if (currentApiUrl) {
    return currentApiUrl.split(".")[1];
  }
  return "";
}

function isProd(): boolean {
  return getCurrentEnv() === "prod";
}

const env_utils = {
  getCurrentEnv,
  isProd,
};

export default env_utils;
