import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useAppAnalytics from 'hooks/useAppAnalytics';

const PostHogPageViewTracker = () => {
  const location = useLocation();
  const appAnalytics = useAppAnalytics();

  useEffect(() => {
    appAnalytics.capturePageView();
  }, [location, appAnalytics])

  return null;
};

export default PostHogPageViewTracker;
