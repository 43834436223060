import { useContext, useState } from "react";
import "./styles.scss";

import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth/useAuth";
import { useModal } from "react-hooks-use-modal";
import ThemeContext from "contexts/theme/ThemeContext";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import { useTranslation } from "react-i18next";
import helpers from "api/helpers/helpers";
import regex_utils from "utils/regex_utils";

import AuthLayout from "layouts/AuthLayout";
import Input from "components/ui-kit/Input";
import Button from "components/ui-kit/Buttons/Button";
import challengeApi from "api/challengeApi";
import EmailVerificationModal from "./components/EmailVerificationModal";
import useAppAnalytics from 'hooks/useAppAnalytics'
import { warn } from "console";

function LoginPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [Modal, open, close, isOpen] = useModal("root", {
    closeOnOverlayClick: true
  });
  const { t } = useTranslation();
  const { dark } = useContext(ThemeContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [is2fa, setIs2fa] = useState(false);
  const [code2fa, setCode2fa] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const appAnalytics = useAppAnalytics();

  const handleLogin = () => {
    setIsLoading(true);
    auth
      ?.login({ email: email, password: password })
      .then((res) => {
        appAnalytics.identifyUser({ userId: res.user.id, userRole: res.user.role, companyId: res.user.company?.id, companyName: res.user.company?.name });
        navigate("/", { replace: true });
        notificationSuccess(t("authentication_pages.welcome_to_notme"), dark);
      })
      .catch((err) => {
        console.log(err.response?.headers["www-authenticate"]);
        if (err.response?.headers["www-authenticate"] === "Verify email") {
          // Open email verification modal
          open();
        } else if (err.response?.headers["www-authenticate"] === "2FA") {
          challengeApi.postMfa(email, password).then((response) => {
            setIs2fa(true);
          });
        } else {
          notificationError(helpers.formatErrorMessage(err), dark);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendCode2fa = () => {
    setIsLoading(true);
    auth
      ?.login({ email: email, password: password, challenge_code: code2fa })
      .then((res) => {
        appAnalytics.identifyUser({ userId: res.user.id, userRole: res.user.role, companyId: res.user.company?.id, companyName: res.user.company?.name });
        navigate("/", { replace: true });
        notificationSuccess(t("authentication_pages.welcome_to_notme"), dark);
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loginButtonDisabled = () => {
    return (
      email.length === 0 ||
      regex_utils.emailRegex.test(email) === false ||
      password.length < 8
    );
  };

  const sendCode2faButtonDisabled = () => {
    return code2fa.length !== 6;
  };

  return (
    <AuthLayout
      title={
        !is2fa
          ? t("authentication_pages.log_in")
          : t("authentication_pages.enter_confirmation_code")
      }
      className="login-page"
    >
      {!is2fa ? (
        <form className="auth-layout-inputs">
          <Input
            type="email"
            label={t("user_data.email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            label={t("user_data.password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onEnterPressed={() =>
              !is2fa && !loginButtonDisabled() && !isLoading && handleLogin()
            }
          />
          <Link
            to={"/forgot-password"}
            state={{ canGoBack: true }}
            className="login-page-forgot-password"
          >
            {t("authentication_pages.forgot_password_question")}
          </Link>
        </form>
      ) : (
        <div className="auth-layout-inputs">
          <h6>{t("authentication_pages.confirmation_code_sent")}</h6>
          <Input
            type="code"
            maxLength={6}
            label={t("authentication_pages.confirmation_code")}
            value={code2fa}
            onChange={(e) => setCode2fa(e.target.value)}
            onEnterPressed={() =>
              is2fa &&
              !sendCode2faButtonDisabled() &&
              !isLoading &&
              sendCode2fa()
            }
          />
        </div>
      )}
      <div className="auth-layout-buttons">
        {!is2fa ? (
          <Button
            full
            onClick={handleLogin}
            loading={isLoading}
            disabled={loginButtonDisabled()}
          >
            {t("authentication_pages.log_in")}
          </Button>
        ) : (
          <Button
            full
            onClick={sendCode2fa}
            loading={isLoading}
            disabled={sendCode2faButtonDisabled()}
          >
            {t("authentication_pages.verify_confirmation_code")}
          </Button>
        )}
        <p>
          {t("authentication_pages.new_user") + " "}
          <Link to={"/sign-up"} state={{ canGoBack: true }}>
            {t("authentication_pages.sign_up")}
          </Link>
        </p>
      </div>
      <EmailVerificationModal
        Modal={Modal}
        open={open}
        close={close}
        isOpen={isOpen}
        email={email}
      />
    </AuthLayout>
  );
}

export default LoginPage;
