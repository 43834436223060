import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";

import "./utils/translations/i18n";
import App from "./App";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import posthog from 'posthog-js';
import env_utils from "utils/env_utils";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(advancedFormat);

Sentry.init({
  dsn: "https://b5282a550fb9402abddd257195884b54@o125541.ingest.sentry.io/6490070",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
});

// Load posthog only in production
if (env_utils.isProd()) {
  posthog.init(
    process.env.REACT_APP_POSTHOG_TOKEN || "",
    {
      api_host: process.env.REACT_APP_POSTHOG_HOST,
      capture_pageview: false,
    }
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
